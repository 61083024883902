<template>
  <div class="spoiler-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SpoilerWrapper',
  props: {
    onlyOneActive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    elementsNum() {
      return this.elements().length
    },
    activeElements() {
      return this.elements().filter(el => el.spoilerActive)
    },
  },
  methods: {
    elements() {
      return this.$children.map(el => {
        // eslint-disable-next-line no-prototype-builtins
        if (el.hasOwnProperty('spoilerActive')) {
          return el
        }

        // eslint-disable-next-line no-prototype-builtins
        return el.$children.find(child => child.hasOwnProperty('spoilerActive'))
      })
    },
    closeAll() {
      this.elements.forEach(el => el.close())
    },
    openAll() {
      this.elements.forEach(el => el.open())
    },
  },
}
</script>
